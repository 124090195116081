<template>
  <div id="PainelGrid">
    <div class="main-title headline lighten-2 pt-0 pb-9 px-1" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <!-- <v-app-bar flat color="rgba(0, 0, 0, 0)" c> -->
        <v-toolbar-title ref="titulo_cabecalho" id="titulo_cabecalho" class="text-white pt-3 pb-4 ml-4 mb-n3">
          Painel do Gestor
        </v-toolbar-title>
      <!-- </v-app-bar> -->

      <div class="d-flex align-center w-100 mb-3">
        <v-app-bar flat color="rgba(0, 0, 0, 0)" >
            <v-select
              :items="empreendimentos"
              v-model="empreendimento_selecionado"
              item-text="empreend_nome"
              item-value="cod_empreendimento"
              label="Empreendimento"
              filled
              dense
              required
              flat
              solo-inverted
              clearable
              class="search-input"
              dark
              v-bind:class="{'search-input-longo': search_input_focus}"
              @focus="onFocus($event)"
              @blur="onBlur($event)"
              tabindex="-1"
            ></v-select>
            <v-spacer></v-spacer>
            <v-menu :close-on-content-click="false"
                    open-on-hover
                    bottom
                    offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  ref="btnOpcoes"
                  :small="isMobile"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  class="ml-n5"
                >
                  <v-icon class="mr-1" color="light-blue accent-2">mdi-view-headline</v-icon>
                  <span align="right">Opções</span>
                </v-btn>
              </template>

              <!-- Menus de Relatorios -->
              <v-card elevation="0" style="min-width:400px">
                <v-card-text>
                  <v-treeview
                    ref="tree"
                    v-model="tree_opcoes"
                    :items="menu_config"
                    :open-all="false"
                    item-key="name"
                    activatable
                    return-object
                    open-on-click
                    dense
                    hoverable
                    expand-icon="mdi-chevron-down"
                    on-icon="mdi-chevron"
                    off-icon="mdi-chevron"
                    indeterminate-icon="mdi-chevron"
                    @update:active="abreRelatorioExterno"
                  >
                    <template class="ml-n6" v-slot:prepend="{ item }">
                      <!-- <v-icon
                        v-if="item.children">
                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                      </v-icon> -->
                      <v-icon
                        clsss="pa-0"
                        v-if="!item.children">
                        {{ item.icon }}
                      </v-icon>
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-menu>
        </v-app-bar>
      </div>
  </div>

    <v-container class="container-table mt-n11 pa-0"  v-bind:style="{ 'background-color':COR_SECUNDARIA }">
      <grid-layout
      :layout.sync="layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[15, 15]"
      :use-css-transforms="true"
      >
        <grid-item v-for="item in layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        @resize="resizeEvent"
        @resized="resizedEvent"
        @container-resized="containerResizedEvent"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag"
        >
          <component
            :is="item.template"
            :empreendimento_selecionado="empreendimento_selecionado"
            :itemId="item.i"
            :gridheight.sync="gridheight[item.i]"
          ></component>
        </grid-item>
      </grid-layout>
    </v-container>

  </div>
</template>


<script>
import VueGridLayout from 'vue-grid-layout';

import { API } from "../../services/API";
import { AbreRelatorioExterno, MenuRelatorioGet } from "../../services/global"
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../services/constantes";

import PaineCardResumoVenda           from "./PaineisGrid/PaineisGrids/PaineCardResumoVenda.vue"
import PaineCardSituacaoUnidadesChart from './PaineisGrid/PaineisGrids/PaineCardSituacaoUnidadesChart.vue';
import PaineCardInadimplencia         from './PaineisGrid/PaineisGrids/PaineCardInadimplencia.vue';
import PaineCardLiquidacaoRecebidos   from "./PaineisGrid/PaineisGrids/PaineCardLiquidacaoRecebidos.vue";
import PaineCardPrevisaoRecebimento   from "./PaineisGrid/PaineisGrids/PaineCardPrevisaoRecebimento.vue";

export default {
  name:"PainelGrid",

  components: {
    PaineCardResumoVenda,
    PaineCardSituacaoUnidadesChart,
    PaineCardInadimplencia,
    PaineCardLiquidacaoRecebidos,
    PaineCardPrevisaoRecebimento,

    GridLayout : VueGridLayout.GridLayout,
    GridItem   : VueGridLayout.GridItem

  },

  data() {
    return {

      draggable: true,
      resizable: true,
      index: 0,
      gridheight : [],

      enabled               : null,
      relatorioSelecionado  : {},
      AbreRelatorioExterno  : AbreRelatorioExterno,
      MenuRelatorioGet      : MenuRelatorioGet,
      tree_opcoes           : [],

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,

      search_input_focus : false,

      empreendimentos: [],
      empreendimento_selecionado: null,

      active: [],
      //  files: {
      //   html: 'mdi-language-html5',
      //   js: 'mdi-nodejs',
      //   json: 'mdi-code-json',
      //   md: 'mdi-language-markdown',
      //   pdf: 'mdi-file-pdf',
      //   png: 'mdi-file-image',
      //   txt: 'mdi-file-document-outline',
      //   xls: 'mdi-file-excel',
      //   xls: 'mdi-file-word',
      //   xls: 'mdi-file-pdf-box',
      // },

      layout: [
                {"x":0, "y":0, "w":this.isMobile ? 12 : 12, "h":12, "i":"0", static: false, template: "PaineCardResumoVenda"},
                {"x":0, "y":1, "w":this.isMobile ? 12 : 6,  "h":12, "i":"1", static: false, template: "PaineCardSituacaoUnidadesChart"},
                {"x":6, "y":1, "w":this.isMobile ? 12 : 6,  "h":12, "i":"2", static: false, template: "PaineCardInadimplencia"},
                {"x":0, "y":2, "w":this.isMobile ? 12 : 12, "h":12, "i":"3", static: false, template: "PaineCardLiquidacaoRecebidos"},
                {"x":0, "y":3, "w":this.isMobile ? 12 : 12, "h":12, "i":"4", static: false, template: "PaineCardPrevisaoRecebimento"},
              ],

      menu_config: [],

    };
  },

  async mounted() {
    
    // Busca provilegios de acesso ao empreendimento
    await this.empreendimento_privilegio_acesso();
    if (this.empreendimentos.length > 0)
      this.empreendimento_selecionado = this.empreendimentos[0].cod_empreendimento;
    
    // Busca menu para esta aplicacao
    await this.menu_relatorio("PainelGestor");

    this.isMobile;

  },

  methods: {
    resizeEvent: function(i, newH, newW, newHPx, newWPx){
      //console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx);
      this.gridheight[i] = parseInt(newHPx);
    },
    resizedEvent: function(i, newH, newW, newHPx, newWPx){
      //console.log("RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx, this.layout);
      this.gridheight[i] = parseInt(newHPx);
    },
    containerResizedEvent: function(i, newH, newW, newHPx, newWPx) {
      //console.log("CONTAINER RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx);
      this.gridheight[i] = parseInt(newHPx);
      // console.log('XXXXXXXXXXXXXXXXXXXXX', this.$vuetify.breakpoint.name, this.layout)
      if (this.$vuetify.breakpoint.name == 'xs') {
       // console.log('XXXXXXXXXXXXXXXXXXXXX', this.$vuetify.breakpoint.name, this.layout)
        this.layout = [
          {"x":0, "y":0, "w":12, "h":3,  "i":"0", static: false, template: "PaineCardResumoVenda"},
          {"x":0, "y":1, "w":12, "h":12, "i":"1", static: false, template: "PaineCardSituacaoUnidadesChart"},
          {"x":0, "y":2, "w":12, "h":12, "i":"2", static: false, template: "PaineCardInadimplencia"},
          {"x":0, "y":3, "w":12, "h":12, "i":"3", static: false, template: "PaineCardLiquidacaoRecebidos"},
          {"x":0, "y":4, "w":12, "h":12, "i":"4", static: false, template: "PaineCardPrevisaoRecebimento"},
        ]
      }
      else {
        this.layout = [
          {"x":0, "y":0, "w":12, "h":3,  "i":"0", static: false, template: "PaineCardResumoVenda"},
          {"x":0, "y":1, "w":6,  "h":12, "i":"1", static: false, template: "PaineCardSituacaoUnidadesChart"},
          {"x":6, "y":1, "w":6,  "h":12, "i":"2", static: false, template: "PaineCardInadimplencia"},
          {"x":0, "y":2, "w":12, "h":12, "i":"3", static: false, template: "PaineCardLiquidacaoRecebidos"},
          {"x":0, "y":3, "w":12, "h":12, "i":"4", static: false, template: "PaineCardPrevisaoRecebimento"},
        ]
      }

    },

    // Abrindo Relatorio Externo
    async abreRelatorioExterno(node) {
      if (node) {
        if (node[0].id) {
          this.$refs.titulo_cabecalho.focus();

          // Foi preciso usar variavel auxiliar para contornar erro do Vuetify quando clica pela segunda vez não pega o Node
          var l_Aux = this.menu_config;
          this.menu_config = [];
          await this.AbreRelatorioExterno(node[0].id);
          this.menu_config = l_Aux;
        }
      }
    },

    async menu_relatorio(p_programa) {
      var la_Res = [];
      la_Res = await this.MenuRelatorioGet(p_programa);
      this.menu_config = la_Res;
    },

    isEnabled(slot) {
      return this.enabled === slot;
    },

    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },

  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

  },

  watch: {
    isMobile(){  
      //console.log('MOBILE', this.$vuetify.breakpoint.name === 'xs')
      if (this.$vuetify.breakpoint.name === 'xs') {
        this.layout = [
          {"x":0, "y":0, "w":12, "h":3,  "i":"0", static: false, template: "PaineCardResumoVenda"},
          {"x":0, "y":1, "w":12, "h":12, "i":"1", static: false, template: "PaineCardSituacaoUnidadesChart"},
          {"x":0, "y":2, "w":12, "h":12, "i":"2", static: false, template: "PaineCardInadimplencia"},
          {"x":0, "y":3, "w":12, "h":12, "i":"3", static: false, template: "PaineCardLiquidacaoRecebidos"},
          {"x":0, "y":4, "w":12, "h":12, "i":"4", static: false, template: "PaineCardPrevisaoRecebimento"},
        ]
      }
      else {
        this.layout = [
          {"x":0, "y":0, "w":12, "h":3,  "i":"0", static: false, template: "PaineCardResumoVenda"},
          {"x":0, "y":0, "w":6,  "h":12, "i":"1", static: false, template: "PaineCardSituacaoUnidadesChart"},
          {"x":6, "y":1, "w":6,  "h":12, "i":"2", static: false, template: "PaineCardInadimplencia"},
          {"x":0, "y":2, "w":12, "h":12, "i":"3", static: false, template: "PaineCardLiquidacaoRecebidos"},
          {"x":0, "y":3, "w":12, "h":12, "i":"4", static: false, template: "PaineCardPrevisaoRecebimento"},
        ]

      // layout: [
      //           {"x":0, "y":0, "w":this.$vuetify.breakpoint.name==='xs'?12:6, "h":12, "i":"0", static: false, template: "PaineCardSituacaoUnidadesChart"},
      //           {"x":6, "y":0, "w":this.$vuetify.breakpoint.name==='xs'?12:6, "h":12, "i":"1", static: false, template: "PaineCardInadimplencia"},
      //           {"x":0, "y":1, "w":this.$vuetify.breakpoint.name==='xs'?12:12,"h":12, "i":"2", static: false, template: "PaineCardLiquidacaoRecebidos"},
      //           {"x":0, "y":2, "w":this.$vuetify.breakpoint.name==='xs'?12:12,"h":12, "i":"3", static: false, template: "PaineCardPrevisaoRecebimento"},
      //         ],
      }

    }
  }

};
</script>

<style scoped>
*,
a {
  text-decoration: none;
}

.card-price {
  background: #274abb;
  color: #fff !important;
}

.card-price span {
  color: #fff !important;
  opacity: 0.8;
  font-size: 20px;
}

.card-location {
  background: #4bc0c0;
  color: #fff !important;
}

.card-location span {
  opacity: 0.8;
  font-size: 20px;
}

.card-status {
  background: #9966ff;
  color: #fff !important;
}

.card-status span {
  opacity: 0.8;
  font-size: 20px;
}

.card-type {
  background: #519e8a;
  color: #fff !important;
}

.card-type span {
  opacity: 0.8;
  font-size: 20px;
}

.card-price .body-2,
.card-location .body-2,
.card-status .body-2,
.card-type .body-2 {
  color: #fff !important;
  opacity: 0.9;
}

.card-type span {
  background: #389f83;
}

.body-2 {
  display: flex;
  justify-content: space-between;
  font-size: 13px !important;
}

.body-3 {
  font-size: 16px !important;
  color: #fff;
}

.icon-dashboard {
  font-size: 2.2rem;
  color: #fff;
  opacity: 0.6;
}

.card-price span,
.card-location span,
.card-status span,
.card-type span {
  margin-top: -12px;
  padding-left: 16px;
}

.card-container {
  overflow-x: auto;
}

@media (max-width: 599px) {
  .card-container {
    margin-top: 24px;
  }
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.sticky-table-footer-mobile {
  display: none;
}

@media (max-width: 599px) {
  .sticky-table-footer-desktop {
    display: none;
  }

  .container-footer-total {
    padding-right: 0px!important;
    padding-left: 0px!important;
  }

  .sticky-table-footer-mobile {
    display: block;
  }

  .sticky-table-footer-mobile td {
    margin-top: 10px;
    padding: 4px;
    font-weight: bold;
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
    color: #3268a3;
    font-size: 14px;
  }
}

.main-title {
  background: #0548a3;
}

.text-white {
  font-size: 20px;
  color: #FFFFFF !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
@media (max-width: 599px) {
  .container-table {
    padding: 0px 0px !important;
  }
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 50% 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
}

@media (max-width: 599px) {
  .search-input {
    width: 200px;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    min-width: 100%;
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 4px !important;
    padding-left: 2px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: 100% !important;
  border-radius: 16px 16px 0px 0px;
  background: #f2f6f7;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.height {
  height: 513px !important;
}

@media (min-width: 1441px) {
  .height {
    height: initial !important;
  }
}

@media (max-width: 768px) {
  .height {
    height: initial !important;
  }
}

.row-date {
  padding-top: 18px !important;
}

@media (max-width: 599px) {
  .row-date {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .td-mobile {
    display: flex;
  }

  .label-date-solo {
    display: none;
  }
}

.label-date {
  font-size: 14px;
  font-weight: 500;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

@media(min-width: 1441px) {
  .col-inadimplencia .min-height {
    /* height: 733px!important; */
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .col-inadimplencia,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .col-inadimplencia,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.table-mobile {
  display: none;
}

@media (max-width: 599px) {
  .table-mobile {
    display: block;
  }

  .tbody-mobile {
    padding: 12px !important;
  }

  .font-weight-bold {
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.6);
  }
}

.last-tr {
  border-bottom: 1px solid #dddcdc !important;
}

.cor_titulo_painel {
  font-size: 18px !important;
  color: #3268a3;
}

.col-font-sumario {
  font-size: 15px !important;
  color: #3268a3;
}

.col-font {
  font-size:13px !important
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250 ,250, 250);
}

.w-100 {
  width: 100%;
}

.card-size {
min-width: 150px; 
  height: 100px;
}

.date-mobile {
  margin-top: -16px!important;
  margin-bottom: 34px!important;
}

.data-input {
  font-weight: 400;
  font-size: 15px;
}

/* ///////////////////////////////////////////////////////////////// */

.container .vue-grid-item.vue-grid-placeholder {
  background: green;
}
.vue-grid-layout {
  /* background: #eee; */
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    /* border: 1px solid black; */
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}

</style>
